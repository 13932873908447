<template>
    <div class="MobileDetail">
        <div class="MobileDetail__Header"  v-on:click="handleBack">
            <img src="@/assets/common/left.svg" />
            <span class="text__psbb"> {{ tabTitle() }} </span>
        </div>
        <div class="MobileDetail__Body">
          <RequestHeader
              :request="request"
          />
          <div
            class="MobileDetail__Body__Content"
            v-if="currentTab !== undefined"
          >
            <hr/>
            <RequestSummaryInfo
              v-if="currentTab === 'summary'"
              :request="request"
              isDetail
            />
            <DocumentTable
              v-if="hasDocuments && currentTab === 'documents'"
              :items="request.documents"
              canDownload
            />
            <NotesTable
              v-if="hasNotes && currentTab === 'notes'"
              :items="request.notes"
              @handleShowDetail="handleShowViewNote"
            />
            <MobileCalendarTable
              v-if="currentTab === 'dates'"
              :items="request.appointments"
            />
            <RequestCollaboration
              v-if="currentTab === 'collaborations'"
              :collaboratorClinics="request.collaborators"
              :mainClinic="request.clinic"
              :pendindInvitations="request.invitations"
            />
          </div>
          <div
            class="MobileDetail__Body__Content"
            v-if="currentTab === undefined"
          >
            <hr/>
            <ContactBlock
                :user="request.patient"
                :isAccepted="isInvitationAccepted && isRequestAccepted"
            />
            <hr/>
            <RequestMiniCardBlock
                :request="request"
            />
            <hr/>
            <TabButton
                :title="$i18n.t('lb_summary')"
                tab="summary"
                @handleTabSelection="selectTab"
            />
            <hr/>
            <TabButton
                :title="hasDocuments
                    ? $i18n.t('lb_documents_count', { count: request.documents.length })
                    : $i18n.t('lb_documents')"
                tab="documents"
                @handleTabSelection="selectTab"
            />
            <hr/>
            <TabButton
                :title="hasNotes
                    ? $i18n.t('lb_notes_count', { count: request.notes.length })
                    : $i18n.t('lb_notes')"
                tab="notes"
                @handleTabSelection="selectTab"
            />
            <hr/>
            <TabButton
                :title="hasAppointments
                    ? $i18n.t('lb_dates_count', { count: request.appointments.length })
                    : $i18n.t('lb_dates')"
                tab="dates"
                @handleTabSelection="selectTab"
            />
            <hr/>
            <TabButton
                :title="$i18n.t('lb_collaborations')"
                tab="collaborations"
                @handleTabSelection="selectTab"
            />
            <hr/>
            <div class="MobileDetail__Body__Footer"
              v-if="invitationData.status === 'pending' || request.status === 'pending'"
            >
                <b-button
                  v-on:click="reject"
                  v-if="invitationData.type !== 'sent'"
                >{{ $i18n.t('btn_reject') }}</b-button>
                <b-button
                  variant="primary"
                  v-on:click="accept"
                  v-if="invitationData.type !== 'sent'"
                >{{ $i18n.t('btn_accept') }}</b-button>
                <b-button
                  v-on:click="cancel"
                  v-if="invitationData.type === 'sent'"
                >{{ $i18n.t('btn_cancel') }}</b-button>
            </div>
          </div>
        </div>
        <NoteModal
          id="requestDetailFormNoteModal"
          ref="requestDetailFormNoteModal"
        />
    </div>
</template>
<script>
import RequestMiniCardBlock from '@/components/molecules/Request/RequestMiniCardBlock/RequestMiniCardBlock.vue';
import ContactBlock from '@/components/molecules/ContactBlock/ContactBlock.vue';
import RequestHeader from '@/components/molecules/Request/RequestHeader/RequestHeader.vue';
import { STATUS } from '@/helpers/ComponentHelper';
import RequestSummaryInfo from '../../RequestSummaryInfo.vue';
import DocumentTable from '@/components/molecules/Tables/DocumentTable/DocumentTable.vue';
import NotesTable from '@/components/molecules/Tables/NotesTable/NotesTable.vue';
import NoteModal from '@/components/molecules/Modal/NoteModal.vue';
import MobileCalendarTable from '@/components/molecules/Tables/CalendarTable/MobileCalendarTable.vue';
import RequestCollaboration from '../../RequestCollaboration.vue';

export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
    invitation: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {
      currentTab: undefined,
      invitationData: this.invitation || {
        id: null,
        status: null,
        type: null,
      },
    };
  },
  computed: {
    hasDocuments() {
      return this.request.documents !== undefined && this.request?.documents.length > 0;
    },
    hasNotes() {
      return this.request.notes !== undefined && this.request?.notes.length > 0;
    },
    hasAppointments() {
      return this.request.appointments !== undefined && this.request?.appointments.length > 0;
    },
    isRequestAccepted() {
      const req = this.request.status.toUpperCase() === STATUS.ACCEPTED.key.toUpperCase();
      return req;
    },
    isInvitationAccepted() {
      const inv = this.invitationData.status !== null
        ? this.invitationData.status.toUpperCase() === STATUS.ACCEPTED.key.toUpperCase()
        : true;
      return inv;
    },
  },
  methods: {
    handleBack() {
      if (this.currentTab === undefined) {
        this.$emit('handleClose');
      } else {
        this.currentTab = undefined;
      }
    },
    tabTitle() {
      switch (this.currentTab) {
        case 'summary':
          return this.$i18n.t('lb_summary');
        case 'documents':
          return this.$i18n.t('lb_documents');
        case 'notes':
          return this.$i18n.t('lb_notes');
        case 'dates':
          return this.$i18n.t('lb_dates');
        case 'collaborations':
          return this.$i18n.t('lb_collaborations');
        default:
          return '';
      }
    },
    selectTab(tab) {
      this.currentTab = tab;
    },
    async reject() {
      if (this.invitationData.id === null) {
        const requestData = {
          id: this.request.id,
          body: {
            status: STATUS.REJECTED.key,
          },
        };
        await this.$store.dispatch('request/changeRequestStatus', requestData);
      } else {
        await this.$store.dispatch('invitations/rejectInvitation', this.invitation.id);
      }
      this.handleBack();
    },
    async accept() {
      if (this.invitationData.id === null) {
        const requestData = {
          id: this.request.id,
          body: {
            status: STATUS.ACCEPTED.key,
          },
        };
        await this.$store.dispatch('request/changeRequestStatus', requestData);
      } else {
        await this.$store.dispatch('invitations/acceptInvitation', this.invitation.id);
      }
      this.handleBack();
    },
    async cancel() {
      this.$emit('handleCancel', this.invitation.id);
      await this.$store.dispatch('invitations/cancelInvitation', this.invitation.id);
      this.handleBack();
    },
    handleShowViewNote(note) {
      this.$refs.requestDetailFormNoteModal.setNote(note);
      this.$bvModal.show('requestDetailFormNoteModal');
    },
  },
  components: {
    RequestMiniCardBlock,
    ContactBlock,
    RequestHeader,
    RequestSummaryInfo,
    DocumentTable,
    NotesTable,
    NoteModal,
    MobileCalendarTable,
    RequestCollaboration,
    TabButton: () => import('@/components/atoms/TabButton/TabButton.vue'),
  },
};
</script>
<style lang="sass" scoped>
  @import './Detail.sass'
</style>
