<template>
    <div class="mobile_calendar_list">
        <div
            class="w-full mobile_calendar_list__item"
            v-for="(item,index) of items"
            :key="index"
        >
            <span class="text__psb">{{ getDate(item.createdAt) }}</span>
            <span class="text__psb">{{ getHour(item.createdAt) }}</span>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    getDate(date) {
      return moment(String(date)).format('DD/MM/YYYY');
    },
    getHour(date) {
      return moment(String(date)).format('hh:mm');
    },
  },
};
</script>
<style lang="sass" scoped>
  @import './Calendar.sass'
</style>
